﻿import { getChartContext } from "@ts/chartContext.ts";
import {
  Chart,
  ChartConfiguration,
  ChartDataset,
  Colors,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import { addYears, dateDiff, DateOnly, isLeapDay } from "@ts/dates.ts";
import { average, standardDeviation } from "@ts/arrays.ts";
import { toDashCase } from "@ts/utils.ts";
import type { NullableNumber } from "@ts/types.ts";
import { round } from "@ts/numbers.ts";

interface DqMetricHistoryNullableNumberFields {
  sleepHours: NullableNumber[];
  sleepQuality: NullableNumber[];
  fatigueSleepMore: NullableNumber[];
  fatigueActivityDesire: NullableNumber[];
  fatigueLevelToday: NullableNumber[];
  moodFeeling: NullableNumber[];
  moodTrainSatisfaction: NullableNumber[];
  stressLevel: NullableNumber[];
  travelHours: NullableNumber[];
}

interface DqMetricHistoryBase extends DqMetricHistoryNullableNumberFields {
  isSubmit: boolean[];
}

interface DqMetricHistoryDto extends DqMetricHistoryBase {
  dates: string[];
}

interface DqMetricHistory extends DqMetricHistoryBase {
  dates: Date[];
}

const dQHistoryFromDto = (
  dto: DqMetricHistoryDto | null,
): DqMetricHistory | null => {
  if (dto === null) {
    return null;
  }

  const { dates, ...rest } = dto;
  return {
    dates: dates.map((dtString) => DateOnly(new Date(dtString))),
    ...rest,
  };
};

const timePeriods = {
  0: "Season",
  1: "YTD",
  2: "Weeks",
} as const;

type TimePeriod = (typeof timePeriods)[keyof typeof timePeriods];

interface DqChartArgsDto {
  histories: (DqMetricHistoryDto | null)[];
  fromDate: string | null;
  toDate: string | null;
  timePeriod: keyof typeof timePeriods;
}

interface DqChartArgs {
  histories: (DqMetricHistory | null)[];
  fromDate: Date;
  toDate: Date;
  timePeriod: TimePeriod;
}

const argsFromDto = (dto: DqChartArgsDto): DqChartArgs => {
  const { histories, fromDate, toDate, timePeriod } = dto;
  const startOfSeason = "2024-12-01";
  const endOfSeason = "2025-10-31";
  const args: DqChartArgs = {
    histories: histories.map(dQHistoryFromDto),
    fromDate: DateOnly(new Date(fromDate ?? startOfSeason)),
    toDate: DateOnly(new Date(toDate ?? endOfSeason)),
    timePeriod: timePeriods[timePeriod],
  };
  return args;
};

type ChartData = { x: Date; y: NullableNumber }[];

Chart.register([
  LineController,
  LineElement,
  Filler,
  PointElement,
  LinearScale,
  TimeScale,
  Tooltip,
  Legend,
  Colors,
  Title,
]);

const datasetColors = [
  {
    backgroundColor: "rgba(54, 162, 235, 0.25)",
    borderColor: "rgba(54, 162, 235, 1)",
    pointBackgroundColor: "rgba(54, 162, 235, 1)",
  },
  {
    backgroundColor: "rgba(255, 99, 132, 0.1)",
    borderColor: "rgba(255, 99, 132, 0.2)",
    pointBackgroundColor: "rgba(255, 99, 132, 0.5)",
  },
  {
    backgroundColor: "rgba(255, 159, 64, 0.1)",
    borderColor: "rgba(255, 205, 86, 0.2)",
    pointBackgroundColor: "rgba(255, 205, 86, 0.5)",
  },
];

const lineChartFields: (keyof DqMetricHistoryNullableNumberFields)[] = [
  "sleepHours",
  "sleepQuality",
  "fatigueSleepMore",
  "fatigueActivityDesire",
  "fatigueLevelToday",
  "moodFeeling",
  "moodTrainSatisfaction",
  "stressLevel",
  "travelHours",
];

const chartTitles: Record<keyof DqMetricHistoryNullableNumberFields, string> = {
  sleepHours: "Sleep Hours",
  sleepQuality: "Sleep Quality",
  fatigueSleepMore: "Rested",
  fatigueActivityDesire: "Activity Desire",
  fatigueLevelToday: "Energy Level",
  moodFeeling: "Mood",
  moodTrainSatisfaction: "Satisfaction",
  stressLevel: "Stress",
  travelHours: "Travel Hours",
};

const getChartTitle = (
  metric: keyof DqMetricHistoryNullableNumberFields,
): string => chartTitles[metric];

const pointRadiuses: Record<TimePeriod, number> = {
  Season: 0,
  Weeks: 4,
  YTD: 0,
};

const descriptors: Record<number, string> = {
  0: "v.low",
  5: "low",
  10: "mod.",
  15: "high",
  20: "v.high",
};

const yAxisStepSize: Partial<
  Record<keyof DqMetricHistoryNullableNumberFields, number>
> = {
  travelHours: 2,
  sleepHours: 2,
};

const getYAxisStepSize = (field: keyof DqMetricHistoryNullableNumberFields) =>
  yAxisStepSize[field] ?? 5;

const yAxisMax: Partial<
  Record<keyof DqMetricHistoryNullableNumberFields, number>
> = {
  travelHours: 24,
  sleepHours: 16,
};

const getYAxisMax = (field: keyof DqMetricHistoryNullableNumberFields) =>
  yAxisMax[field] ?? 25;

const yAxisCallbacks: Partial<
  Record<
    keyof DqMetricHistoryNullableNumberFields,
    (value: string | number) => number
  >
> = {
  travelHours: (value: string | number) => value as number,
  sleepHours: (value: string | number) => value as number,
};

const getYAxisCallback = (field: keyof DqMetricHistoryNullableNumberFields) =>
  yAxisCallbacks[field] ??
  ((value: string | number) => ((value as number) <= 20 ? value : ""));

const seasons = [2025, 2024, 2023];

/**
 * Assigns
 * Convert all dates to the current year so they align on the chart
 * Remove Feb 29th since it does exist in 2025
 */
const createChartData = (
  values: NullableNumber[],
  dates: Date[],
  season: number,
): ChartData =>
  values.flatMap(
    (y, i) =>
      !isLeapDay(dates[i])
        ? {
            x: addYears(dates[i], 2025 - season),
            y,
          }
        : [], // filtered out by flatMap,
  );

const charts: Record<
  keyof DqMetricHistoryNullableNumberFields,
  Chart<"line", ChartData> | undefined
> = {} as Record<
  keyof DqMetricHistoryNullableNumberFields,
  Chart<"line", ChartData> | undefined
>;

export const drawDqCharts = (dto: DqChartArgsDto) => {
  const { histories, fromDate, toDate, timePeriod } = argsFromDto(dto);

  const oneDayInMs = 24 * 60 * 60 * 1000;

  const allDates = Array.from(
    { length: dateDiff(toDate, fromDate, "days") },
    (_, i) => DateOnly(fromDate.getTime() + oneDayInMs * i),
  );

  const lineConfigs = lineChartFields.map((field) => {
    const datasets: ChartDataset<"line", ChartData>[] = histories
      .flatMap((history, index) => {
        if (history === null) {
          return undefined;
        }
        const season = seasons[index];
        const values = history[field];

        const valueOnDate: Record<number, NullableNumber> = Object.fromEntries(
          history.dates.map((dt, index) => [dt.getTime(), values[index]]),
        );

        // const data = createChartData(values, dates, season);

        const data: ChartData = allDates.map((date) => {
          const dt = DateOnly(addYears(date, season - 2025));
          return {
            x: date,
            y: valueOnDate[dt.getTime()] ?? null,
          };
        });

        const datasets: ChartDataset<"line", ChartData>[] = [];

        // Field Dataset
        datasets.push({
          // Only use dates between fromDate and toDate: used for YTD vs Week Summary
          data: data.filter(({ x }) => fromDate <= x && x <= toDate),
          label: season.toString(),
          tension: 0.25,
          borderWidth: season === 2025 ? 4 : 6,
          yAxisID: "y",
          pointRadius:
            season === 2025 && field !== "travelHours"
              ? pointRadiuses[timePeriod]
              : 0,
          pointHitRadius: 10,
          pointHoverRadius: 10,
          stepped: field === "travelHours" ? "middle" : false,
          fill: field === "travelHours" ? true : false,
          ...datasetColors[index],
        });

        if (season === 2025 && field !== "travelHours") {
          // Calculate the Mean and StdDev based on full data set
          const mean = round(average(values), 2);
          const stdDev = round(standardDeviation(values), 2);
          const meanPlusStdDev = mean + stdDev;
          const meanMinusStdDev = mean - stdDev;
          const meanPlusTwoStdDev = mean + 2 * stdDev;
          const meanMinusTwoStdDev = mean - 2 * stdDev;

          // Mean Dataset
          datasets.push({
            data: allDates.map((dt) => ({ x: dt, y: mean })),
            label: `${season} YTD mean`,
            fill: false,
            borderWidth: 2,
            borderDash: [5, 5],
            yAxisID: "y",
            pointRadius: 0,
            pointHitRadius: 1,
            pointHoverRadius: 10,
            borderColor: "black",
          });

          // Std Dev - 2 dataset
          datasets.push({
            data: allDates.map((dt) => ({
              x: dt,
              y: meanMinusTwoStdDev,
            })),
            label: `${season} Mean -2σ`,
            fill: false,
            tension: 0.25,
            borderWidth: 1,
            yAxisID: "y",
            pointRadius: 0,
            pointHitRadius: 1,
            pointHoverRadius: 10,
          });

          // Std Dev - 1 dataset
          datasets.push({
            data: allDates.map((dt) => ({
              x: dt,
              y: meanMinusStdDev,
            })),
            label: `${season} Mean -1σ`,
            fill: false,
            tension: 0.25,
            borderWidth: 1,
            yAxisID: "y",
            pointRadius: 0,
            pointHitRadius: 1,
            pointHoverRadius: 10,
          });

          // Std Dev + 1 dataset
          datasets.push({
            data: allDates.map((dt) => ({
              x: dt,
              y: meanPlusStdDev,
            })),
            label: `${season} Mean +1σ`,
            fill: "-1",
            tension: 0.25,
            borderWidth: 1,
            yAxisID: "y",
            pointRadius: 0,
            pointHitRadius: 1,
            pointHoverRadius: 10,
            backgroundColor: "rgba(0,0,0,0.1)",
          });

          // Std Dev + 2 dataset
          datasets.push({
            data: allDates.map((dt) => ({
              x: dt,
              y: meanPlusTwoStdDev,
            })),
            label: `${season} Mean +2σ`,
            fill: "-3",
            tension: 0.25,
            borderWidth: 1,
            yAxisID: "y",
            pointRadius: 0,
            pointHitRadius: 1,
            pointHoverRadius: 10,
            backgroundColor: "rgba(0,0,0,0.1)",
          });
        }

        return datasets;
      })
      .filter((o) => o !== undefined);

    const config: ChartConfiguration<"line", ChartData> = {
      type: "line",
      data: {
        datasets,
      },
      plugins: [
        {
          id: "verticalHoverLine",
          afterDraw: (chart) => {
            const {
              ctx,
              chartArea: { top, bottom },
            } = chart;
            ctx.save();
            chart.getDatasetMeta(1).data.forEach((dataPoint) => {
              // TODO. Need a method to deactive a point on touch for mobile
              if (dataPoint.active) {
                ctx.beginPath();
                ctx.lineWidth = 3;
                ctx.strokeStyle = "black";
                ctx.moveTo(dataPoint.x, top);
                ctx.lineTo(dataPoint.x, bottom);
                ctx.stroke();
              }
            });
          },
        },
      ],
      options: {
        aspectRatio: 10 / 4,
        maintainAspectRatio: true,
        responsive: true,
        plugins: {
          title: {
            display: false,
            text: getChartTitle(field).toUpperCase(),
            position: "top",
            align: "start",
            font: {
              size: 20,
            },
          },
          legend: {
            display: true,
            align: "start",
            position: "top",
            labels: {
              filter: (item) => !item.text.includes("σ"),
            },
          },
        },
        scales: {
          x: {
            type: "time",
            time: {
              tooltipFormat: "LLL d",
              minUnit: "day",
            },
            grid: {
              display: false,
            },
          },
          y: {
            min: 0,
            max: getYAxisMax(field),
            position: "left",
            ticks: {
              stepSize: getYAxisStepSize(field),
              callback: getYAxisCallback(field),
            },
            grid: {
              color: "#efefef",
            },
          },
          y1: {
            display: field !== "sleepHours" && field !== "travelHours",
            min: 0,
            max: 25,
            position: "left",
            ticks: {
              stepSize: 5,
              callback: (value) =>
                (value as number) <= 20 ? descriptors[value as number] : "",
            },
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
          },
        },
        interaction: {
          mode: "index",
          axis: "x",
          intersect: false,
        },
      },
    };

    return config;
  });

  // Wait a short period of time so the canvas element can be rendered by the UI
  setTimeout(() => {
    lineChartFields.forEach((field, index) => {
      const context = getChartContext(toDashCase(field));
      if (context === undefined) {
        console.log(`Unable to load chart for ${field}`);
        return;
      }

      const config = lineConfigs[index];

      charts[field]?.destroy();
      charts[field] = new Chart(context, config);
    });

    if (location.href.includes("localhost") || location.href.includes("beta")) {
      // attach a reference to the chart to the global window for testing/debugging
      (window as any).charts = charts;
    }
  }, 25);
};
