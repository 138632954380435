import type {
  ScriptableContext,
  ChartConfiguration,
  ChartDataset,
} from "chart.js";
import {
  Chart,
  LineController,
  LineElement,
  Filler,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { getChartContext } from "@ts/chartContext.ts";

interface LineChartArgs {
  datasetLabel: string;
  data: number[];
  yAxisLabels: string[];
  riderMean?: number;
  teamMean?: number;
  numberHighlightFinalPoints: number;
  descriptors: Record<number, string>;
  isShowDescriptors: boolean;
}

Chart.register([
  LineController,
  LineElement,
  Filler,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
]);

let lineChart: Chart | undefined;

export const drawLineChart = (args: LineChartArgs) => {
  const {
    datasetLabel,
    data,
    yAxisLabels,
    riderMean,
    teamMean,
    numberHighlightFinalPoints,
    descriptors,
    isShowDescriptors,
  } = args;

  const isHighlight = (arg: number | ScriptableContext<"line">): boolean => {
    const index = typeof arg === "number" ? arg : arg.dataIndex;
    return index + 1 > data.length - numberHighlightFinalPoints;
  };

  const context = getChartContext("lineChart");
  if (context === undefined) {
    console.log("Unable to load lineChart canvas");
    return;
  }

  const datasets: ChartDataset<"line", number[]>[] = [];

  datasets.push({
    label: datasetLabel,
    data,
    fill: true,
    tension: 0.1,
    borderWidth: 4,
    yAxisID: "y",
    pointBorderColor: (ctx) =>
      isHighlight(ctx) ? "rgb(255, 215, 0)" : "rgb(75, 192, 192)",
    pointBackgroundColor: (ctx) =>
      isHighlight(ctx) ? "rgb(255, 215, 0)" : "rgb(75, 192, 192)",
    pointRadius: (ctx) => (isHighlight(ctx) ? 2 : 1),
    borderColor: "rgb(75, 192, 192)",
    backgroundColor: "rgba(75, 192, 192, 0.1)",
    segment: {
      borderColor: (ctx) =>
        isHighlight(ctx.p0DataIndex) ? "rgb(255, 215, 0)" : undefined,
      backgroundColor: (ctx) =>
        isHighlight(ctx.p0DataIndex) ? "rgb(255, 255, 0, 0.25)" : undefined,
    },
  });

  if (riderMean !== undefined) {
    datasets.push({
      label: "Rider Mean",
      data: data.map(() => riderMean),
      fill: false,
      borderColor: "#f66",
      borderDash: [4, 1],
      pointRadius: 0,
      borderWidth: 2,
      yAxisID: "y",
    });
  }

  if (teamMean !== undefined) {
    datasets.push({
      label: "Team Mean",
      data: data.map(() => teamMean),
      fill: false,
      borderColor: "#000",
      borderDash: [4, 1],
      pointRadius: 0,
      borderWidth: 2,
      yAxisID: "y",
    });
  }

  const config: ChartConfiguration<"line", number[], string> = {
    type: "line",
    data: {
      labels: yAxisLabels,
      datasets,
    },
    options: {
      scales: {
        y: {
          min: 0,
          max: datasetLabel === "SleepHours" ? 15 : 20,
          position: "left",
          ticks: {
            stepSize: 5,
          },
        },
        y1: {
          display: isShowDescriptors,
          min: 0,
          max: 20,
          position: "left",
          ticks: {
            stepSize: 5,
            callback: (value) => descriptors[value as number],
          },
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
        },
      },
    },
  };

  lineChart?.destroy();
  lineChart = new Chart(context, config);
};
