import type { ChartConfiguration, ChartDataset } from "chart.js";
import {
  Chart,
  LineController,
  LineElement,
  Filler,
  PointElement,
  LinearScale,
  TimeScale,
  Tooltip,
  Legend,
  Colors,
} from "chart.js";
import "chartjs-adapter-luxon";
import { getChartContext } from "@ts/chartContext.ts";
import { addYears } from "@ts/dates.ts";

interface PerformanceChartArgsDto {
  chartId: string;
  readinessEntries: {
    score: number;
    comment: string;
    /** Unix timestamp */
    date: number;
  }[];
  races: {
    name: string;
    date: number;
  }[];
  descriptors: Record<number, string>;
}

interface PerformanceChartArgs {
  chartId: string;
  readinessEntries: {
    score: number;
    comment: string;
    date: Date;
  }[];
  races: {
    name: string;
    date: Date;
  }[];
  descriptors: Record<number, string>;
}

const dtoToArgs = (dto: PerformanceChartArgsDto): PerformanceChartArgs => ({
  chartId: dto.chartId,
  readinessEntries: dto.readinessEntries.map((o) => ({
    score: o.score,
    comment: o.comment,
    date: new Date(o.date),
  })),
  races: dto.races.map((o) => ({
    name: o.name,
    date: new Date(o.date),
  })),
  descriptors: dto.descriptors,
});

type ChartData = { x: Date; y: number }[];

Chart.register([
  LineController,
  LineElement,
  Filler,
  PointElement,
  LinearScale,
  TimeScale,
  Tooltip,
  Legend,
  Colors,
]);

const datasetColors = [
  {
    backgroundColor: "rgba(54, 162, 235, 0.5)",
    borderColor: "rgba(54, 162, 235, 1)",
    pointBackgroundColor: "rgba(54, 162, 235, 1)",
  },
  {
    backgroundColor: "rgba(255, 99, 132, 0.25)",
    borderColor: "rgba(255, 99, 132, 0.5)",
    pointBackgroundColor: "rgba(255, 99, 132, 0.5)",
  },
  {
    backgroundColor: "rgba(255, 159, 64, 0.25)",
    borderColor: "rgba(255, 205, 86, 0.5)",
    pointBackgroundColor: "rgba(255, 205, 86, 0.5)",
  },
  {
    backgroundColor: "rgba(0, 0, 0, 1)",
    borderColor: "rgba(0, 0, 0, 1)",
    pointBackgroundColor: "rgba(0, 0, 0, 1)",
  },
];

let performanceChart: Chart<"line", ChartData> | undefined;

const seasons = [2025, 2024, 2023] as const;

// Each season runs from Dec 1st to Oct 31st
const seasonBounds = Object.fromEntries(
  seasons.map((year) => [
    year,
    { start: new Date(year - 1, 11, 1), end: new Date(year, 9, 31) },
  ]),
) as Record<(typeof seasons)[number], { start: Date; end: Date }>;

const multiLineText = (input: string, maxLength: number): string[] => {
  // .{ 1, ${ maxLength } } => Matches between 1 and maxLength characters.
  // (\s | $) => Ensures the match ends at a space or the end of the string.
  const regex = new RegExp(`(.{1,${maxLength}})(\\s|$)`, "g");
  return input.match(regex)?.map((o) => o.trim()) ?? [];
};

export const drawPerformanceChart = (dto: PerformanceChartArgsDto) => {
  const args = dtoToArgs(dto);

  const { chartId, readinessEntries, descriptors, races } = args;

  const entriesBySeason = seasons.map((season) => {
    const { start, end } = seasonBounds[season];
    return readinessEntries.filter((o) => o.date >= start && o.date <= end);
  });

  const datasets: ChartDataset<"line", ChartData>[] = seasons.map(
    (season, index) => {
      const entries = entriesBySeason[index];

      const dataset: ChartDataset<"line", ChartData> = {
        data: entries.map((o) => ({
          // convert all years to the current season
          x: addYears(o.date, 2025 - season),
          y: o.score,
        })),
        label: season.toString(),
        fill: season === 2025,
        tension: 0.5,
        borderWidth: 2,
        yAxisID: "y",
        pointRadius: season === 2025 ? 4 : 2,
        pointHitRadius: 10,
        pointHoverRadius: 10,
        ...datasetColors[index],
      };

      return dataset;
    },
  );

  if (races.length > 0) {
    const raceDataset: ChartDataset<"line", ChartData> = {
      data: races.map((o) => ({
        x: o.date,
        y: 22.5,
      })),
      label: "2025 Races (from Logistics)",
      borderWidth: 0,
      yAxisID: "y",
      pointRadius: 5,
      pointStyle: "rectRounded",
      ...datasetColors[3],
    };
    datasets.push(raceDataset);
  }

  const config: ChartConfiguration<"line", ChartData> = {
    type: "line",
    data: {
      datasets,
    },
    options: {
      aspectRatio: 10 / 4,
      plugins: {
        tooltip: {
          enabled: true,
          callbacks: {
            label: (ctx) =>
              ctx.datasetIndex === 3
                ? races[ctx.dataIndex].name
                : multiLineText(
                    `${ctx.parsed.y} / 20: ${entriesBySeason[ctx.datasetIndex][ctx.dataIndex].comment || "No Comment"}`,
                    50,
                  ),
          },
          position: "nearest",
          padding: 10,
          cornerRadius: 10,
        },
      },
      scales: {
        x: {
          type: "time",
          time: {
            tooltipFormat: "LLL d",
          },
        },
        y: {
          min: 0,
          max: 25,
          position: "left",
          ticks: {
            stepSize: 5,
            callback: (value) => ((value as number) <= 20 ? value : ""),
          },
        },
        y1: {
          display: true,
          min: 0,
          max: 25,
          position: "left",
          ticks: {
            stepSize: 5,
            callback: (value) =>
              (value as number) <= 20 ? descriptors[value as number] : "",
          },
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
        },
      },
    },
  };

  // Wait a short period of time so the canvas element can be rendered by the UI
  setTimeout(() => {
    const context = getChartContext(chartId);
    if (context === undefined) {
      console.log("Unable to load performance chart canvas");
      return;
    }

    performanceChart?.destroy();
    performanceChart = new Chart(context, config);

    if (location.href.includes("localhost") || location.href.includes("beta")) {
      // attach a reference to the chart to the global window for testing/debugging
      (window as any).chart = performanceChart;
    }
  }, 25);
};
