interface DotNetHelper {
  invokeMethodAsync: (taskName: string) => void;
}

export const addResizeListener = (
  elementId: string,
  dotNetHelper: DotNetHelper,
): void => {
  const element = document.getElementById(elementId);
  if (element === null) {
    return;
  }

  const resizeObserver = new ResizeObserver((entries) => {
    entries.forEach(() => {
      dotNetHelper.invokeMethodAsync("OnControlResize");
    });
  });
  resizeObserver.observe(element);
};
