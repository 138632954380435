import type { ChartConfiguration, ScriptableContext } from "chart.js";
import {
  Chart,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { getChartContext } from "@ts/chartContext.ts";

interface BarChartArgs {
  label: string;
  data: number[];
  labels: string[];
}

Chart.register([
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
]);

let barChart: Chart | undefined;

export const drawBarChart = (args: BarChartArgs) => {
  const { label, data, labels } = args;

  const context = getChartContext("barChart");
  if (context === undefined) {
    console.log("Unable to load barChart canvas");
    return;
  }

  const datasetColor =
    label === "IsSubmit"
      ? (ctx: ScriptableContext<"bar">) =>
          (ctx.raw as number) > 0 ? "rgb(75, 192, 192)" : "rgb(255, 0, 0)"
      : "rgb(75, 192, 192)";

  const yMin: number = label === "IsSubmit" ? -1 : 0;
  const yMax: number = label === "IsSubmit" ? 1 : 24;

  const ticksStepSize: number = label === "IsSubmit" ? 1 : 4;

  const isSubmitTicksMap: Record<string, string> = {
    "1": "Yes",
    "0": "",
    "-1": "No",
  };
  const ticksCallback =
    label === "IsSubmit"
      ? (value: string | number) => isSubmitTicksMap[value]
      : (value: string | number) => value;

  const config: ChartConfiguration<"bar", number[], string> = {
    type: "bar",
    data: {
      labels,
      datasets: [
        {
          label,
          data,
          borderColor: datasetColor,
          backgroundColor: datasetColor,
          borderWidth: 1,
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: label !== "IsSubmit",
        },
      },
      scales: {
        y: {
          min: yMin,
          max: yMax,
          position: "left",
          ticks: {
            stepSize: ticksStepSize,
            callback: ticksCallback,
          },
        },
      },
    },
  };

  barChart?.destroy();
  barChart = new Chart(context, config);
};
