export const addYears = (date: Date, years: number): Date => {
  const newDate = new Date(date);
  newDate.setFullYear(newDate.getFullYear() + years);
  return newDate;
};

export const addDays = (date: Date, days: number): Date => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

export const isLeapDay = (date: Date): boolean =>
  // getMonth() is a 0-based month index
  date.getDate() === 29 && date.getMonth() === 1;

type TimeSpan =
  | "millis"
  | "seconds"
  | "minutes"
  | "hours"
  | "days"
  | "weeks"
  | "months"
  | "years";

const timeSpanDivisor: Record<TimeSpan, number> = {
  millis: 1,
  seconds: 1000,
  minutes: 1000 * 60,
  hours: 1000 * 60 * 60,
  days: 1000 * 60 * 60 * 24,
  weeks: 1000 * 60 * 60 * 24 * 7,
  months: 1000 * 60 * 60 * 24 * (365 / 12), // Average month length
  years: 1000 * 60 * 60 * 24 * 365,
};

export const dateDiff = (date1: Date, date2: Date, format: TimeSpan): number =>
  (date1.getTime() - date2.getTime()) / timeSpanDivisor[format];

export const uniqueDates = (dates: Date[]) =>
  Array.from(new Map(dates.map((date) => [date.getTime(), date])).values());

/**
 * Retuns a date with the time values removed
 */
export const DateOnly = (value: number | string | Date) => {
  const dt = new Date(value);
  dt.setHours(0, 0, 0, 0);
  return dt;
};
