import { NullableNumber } from "./types.ts";

type Primitive = string | number | boolean | symbol | bigint | null | undefined;
type PrimitiveArray<T> = T extends Primitive[] ? Primitive[] : never;
type PrimitiveKeys<T> = {
  [K in keyof T]: T[K] extends Primitive ? K : never;
}[keyof T]; // Extract only primitive keys

type NumberArray<T> = T extends NullableNumber[] ? NullableNumber[] : never;
type NumericKeys<T> = {
  [K in keyof T]: T[K] extends NullableNumber ? K : never;
}[keyof T]; // Extract only numeric keys

const isPrimitive = (
  value: unknown,
): value is string | number | boolean | symbol | bigint | null | undefined =>
  value === null ||
  ["string", "number", "boolean", "symbol", "bigint", "undefined"].includes(
    typeof value,
  );

const isNullish = (value: unknown): value is undefined | null =>
  value === undefined || value === null;

const isNumber = (value: unknown): value is number => typeof value === "number";

const sumNumbers = (array: number[]): number =>
  array.reduce<number>((sigma, o) => sigma + o, 0);

/**
 * Returns an array of primitive values filtered out
 */
const getPrimitveArray = <T, K extends PrimitiveKeys<T>>(
  array: PrimitiveArray<T> | T[],
  field?: K,
): Primitive[] =>
  isNullish(field)
    ? array.filter(isPrimitive)
    : (array as T[]).flatMap((o) => (isPrimitive(o[field]) ? o[field] : []));

/**
 * Returns an array of number with non-numeric values filtered out
 */
const getNumberArray = <T, K extends NumericKeys<T>>(
  array: NumberArray<T> | T[],
  field?: K,
): number[] =>
  isNullish(field)
    ? array.filter(isNumber)
    : (array as T[]).flatMap((o) => (isNumber(o[field]) ? o[field] : []));

/**
 * Calculate the sum of numeric values in an array
 * If the array is an array of objects, specify the field you would like to sum
 * Undefined, null and non-numeric types are ignored
 */
export const sum = <T, K extends NumericKeys<T>>(
  array: NumberArray<T> | T[],
  field?: K,
): number => {
  const arr = getNumberArray(array, field);
  return sumNumbers(arr);
};

/**
 * Calculate the average of numeric values in an array
 * If the array is an array of objects, specify the field you would like to sum
 * Undefined, null and non-numeric types are ignored
 */
export const average = <T, K extends NumericKeys<T>>(
  array: NumberArray<T> | T[],
  field?: K,
): number => {
  const arr = getNumberArray(array, field);
  return arr.length > 0 ? sumNumbers(arr) / arr.length : 0;
};

/**
 * Calculate the standard deviation of numeric values in an array
 * If the array is an array of objects, specify the field you would like to sum
 * Undefined, null and non-numeric types are ignored
 */
export const standardDeviation = <T, K extends NumericKeys<T>>(
  array: NumberArray<T> | T[],
  field?: K,
): number => {
  const arr = getNumberArray(array, field);
  if (arr.length === 0) {
    return 0;
  }
  const mean = sumNumbers(arr) / arr.length;
  const variance =
    sumNumbers(arr.map((number) => (number - mean) ** 2)) / arr.length;
  return variance ** (1 / 2);
};

export const unique = <T, K extends PrimitiveKeys<T>>(
  array: PrimitiveArray<T> | T[],
  field?: K,
) => {
  const arr = getPrimitveArray(array, field);
  return [...new Set(arr)];
};
