export const toDashCase = (camelCase: string) =>
  // ([a-z]) ? Matches a lowercase letter.
  // ([A-Z]) ? Matches an uppercase letter immediately after a lowercase letter.
  // '$1-$2' ? Inserts a dash(-) between the lowercase and uppercase letters.
  camelCase.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();

export const toTitleCase = (camelCase: string) =>
  // ([a-z]) ? Matches a lowercase letter.
  // ([A-Z]) ? Matches an uppercase letter immediately after a lowercase letter.
  // '$1 $2' ? Inserts a space between the lowercase and uppercase letters.
  // .replace(/^./, ...) capitalize the first letter of the resulting string
  camelCase
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/^./, (match) => match.toUpperCase());
