export const isOnline = (): boolean => navigator.onLine;

export const getElementWidth = (elementId: string): number =>
  document.getElementById(elementId)?.clientWidth ?? 0;

export const getElementOffsetLeft = (elementId: string): number =>
  document.getElementById(elementId)?.offsetLeft ?? 0;

export const getViewportHeight = (): number => window.innerHeight;

export const getViewportWidth = (): number => window.innerWidth;
