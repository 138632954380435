import IMask from "imask";

type Char = string & { length: 1 };

const createChar = (value: string): Char => {
  if (value.length !== 1) {
    throw new Error("Value must be a single character");
  }
  return value as Char;
};

interface NumberMask {
  /** enable number mask */
  mask: typeof Number;
  /** digits after point, 0 for integers */
  scale?: number;
  /** Any single char */
  thousandsSeparator?: Char;
  /** if true, then pads zeros at end to the length of scale */
  padFractionalZeros?: boolean;
  /** appends or removes zeros at ends */
  normalizeZeros?: boolean;
  /** fractional delimiter  */
  radix?: Char;
  /** symbols to process as radix */
  mapToRadix?: Char[];
  min?: number;
  max?: number;
  autofix?: boolean;
}

const DEFAULT_NUMBER_MASK: NumberMask = {
  mask: Number,
  radix: createChar("."),
  mapToRadix: [".", ","].map(createChar),
  normalizeZeros: false,
  padFractionalZeros: true,
  autofix: false,
};

export const applyNumberMask = (
  elementId: string,
  numberMask: NumberMask,
): void => {
  const input = document.getElementById(elementId);
  if (input === null) {
    return;
  }

  const mask: NumberMask = {
    ...DEFAULT_NUMBER_MASK,
    ...numberMask,
  };

  const Mask = IMask as unknown as (
    input: HTMLElement,
    mask: NumberMask,
  ) => void;

  Mask(input, mask);
};
