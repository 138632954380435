export const scrollToElement = (elementId: string): void => {
  document.getElementById(elementId)?.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });
};

export const scrollToTop = (): void => {
  scrollToElement("root");
};
