export const resizeTextArea = (elementId: string, minHeight: number): void => {
  const offset = 2;

  const textArea = document.getElementById(elementId);
  if (textArea === null) {
    return;
  }

  textArea.style.height = "auto";

  const height: number = Math.max(0, textArea.scrollHeight, minHeight) + offset;
  textArea.style.height = `${height.toString()}px`;
};
